import React, { useContext,useState,useEffect } from 'react'
import { Menu as UikitMenu} from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import { BigNumber } from 'ethers'
import links from './config'

import { GetCurrencyUsdPrice } from './getCurrencyUsdPrice'


const Menu: React.FC = (props) => {

  
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const priceData = useGetPriceData()
  const cakePriceUsd = priceData ? Number(priceData.prices.Cake) : undefined
  const profile = useGetLocalProfile()


    // const nativeCurrecyInUsd = "0.001"
    const [usdPrice,setUsdPrice]= useState("")
  
    useEffect(() => {   
  
          let items = ""
           async function _fetch(){
  
            items = await GetCurrencyUsdPrice()
          
              setUsdPrice(items);
          }
          _fetch()

          // console.log(usdPrice)
      });


  if (usdPrice===''){

    return (
      <UikitMenu
        links={links}
        account={account as string}
        login={login}
        logout={logout}
        currentLang={selectedLanguage?.code || ''}
        cakePriceUsd={Number(0.001)}
        langs={allLanguages}
      //  profile={profile}
        {...props}
      />
    )

  }

    return (
      <UikitMenu
        links={links}
        account={account as string}
        login={login}
        logout={logout}
        currentLang={selectedLanguage?.code || ''}
        langs={allLanguages}
        cakePriceUsd={Number(usdPrice)}
      //  profile={profile}
        {...props}
      />
    )

  

}

export default Menu
